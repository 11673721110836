@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Manrope:wght@400;500;700&display=swap");

:root {
  --brand-color: #0051a2;
}

html,
body {
  min-height: 100vh;
  font-family: "Manrope";
  font-weight: normal;
  color: #484343;
  background-color: #f6f5f3;
}

#root {
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.old_version {
  display: block;
  text-align: right;
}

.old_version_link {
  color: #0051a2;
  text-decoration: underline !important;
}

.filters__header select {
  max-width: 140px;
}

.container {
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 30px;
}

.container--personal-card {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 30px;
}

textarea:focus,
input:focus,
input[type="submit"]:focus {
  outline: 1px solid var(--brand-color);
}


input[type="submit"] {
  border: none;
}


.title-2 {
  font-size: 48px;
  margin: 0;
  padding: 0;
  line-height: 1.3em;
}

.title-3 {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1.3em;
}

.main__header {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.notif {
  font-size: 11px;
}

.border {
  border: 1px solid red;
}

.home-content__title {

  padding: 0 9px;
  font-size: 2em;
  font-weight: 700;
  text-align: left;
  color: var(--brand-color);
}


.events {
  padding: 30px 0 50px;
}

.events__container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0;
  row-gap: 20px;
  column-gap: 20px;
}

.event__organization_title {
  font-size: 21px;
  text-decoration: underline;
}

.notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.notification__msg {
  padding-top: 120px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}

.notification__link {
  color: var(--brand-color);
}

.btn {
  background-color: #ffdf38;
  color: #252525;
  padding: 15px 20px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}

.btn--admin-add {
  padding: 5px 7px;
  color: #484343;
}

.btn--admin-del {
  padding: 5px 7px;
  color: #fff;
  background: tomato;
}

.btn--del {
  background-color: tomato;
  color: #fff;
}

.btn--excel {
  background-color: #0a733b;
  color: #fff;
}

.btn--download {
  background-color: var(--brand-color);
  color: #fff;
  padding: 7px 13px;
}




@media (max-width: 1197px) {
  .home-content__title {
    max-width: 912px;
    text-align: center;
  }
}

@media (max-width: 820px) {
  .event-card {
    display: flex;
    flex-direction: column;
    width: 44%;
    overflow: hidden;
    /* max-width: 280px; */
    border-radius: 5px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    height: 337px;
    justify-content: space-between;
  }
}

@media (max-width: 857px) {
  .main__header {
    display: flex;
    flex-direction: column;
  }
}